export default {
  props: {
    paymentType: { type: Object, required: true },
    orderId: { type: Number, required: false },
    deliveryServiceId: { type: Number, required: false },
    mode: { type: String, required: true },
    options: { type: Object, required: false },
    selected: { type: Boolean, defalut: false }
  }
};
